export const config = {
  contactUsURL:
    "https://qr6efs3c90.execute-api.us-east-1.amazonaws.com/default/contactUsLambda",
  videos: [
    {
      title: "Basic Search",
      link: "https://nd-website-data.s3.ap-southeast-1.amazonaws.com/screen-recordings/Normal-Search.mp4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing lorem ipsum dolor. Cum sociis natoque penatibus",
    },
    {
      title: "Apply selective filters",
      link: "https://nd-website-data.s3.ap-southeast-1.amazonaws.com/screen-recordings/Filtering-Search-Results.mp4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing lorem ipsum dolor. Cum sociis natoque penatibus",
    },
    {
      title: "Search in specific fields",
      link: "https://nd-website-data.s3.ap-southeast-1.amazonaws.com/screen-recordings/Search-in-specific-field-2.mp4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing lorem ipsum dolor. Cum sociis natoque penatibus",
    },
    {
      title: "Searching for a phrase",
      link: "https://nd-website-data.s3.ap-southeast-1.amazonaws.com/screen-recordings/Phrase-search.mp4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing lorem ipsum dolor. Cum sociis natoque penatibus",
    },
    {
      title: "Exporting Search Results",
      link: "https://nd-website-data.s3.ap-southeast-1.amazonaws.com/screen-recordings/Export-Search-Results.mp4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing lorem ipsum dolor. Cum sociis natoque penatibus",
    },
    {
      title: "View search results on map",
      link: "https://nd-website-data.s3.ap-southeast-1.amazonaws.com/screen-recordings/Visualize-Search-Results-on-map.mp4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing lorem ipsum dolor. Cum sociis natoque penatibus",
    },
    {
      title: "Search within a spatial region",
      link: "https://nd-website-data.s3.ap-southeast-1.amazonaws.com/screen-recordings/Search-in-specific-geolocation.mp4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing lorem ipsum dolor. Cum sociis natoque penatibus",
    },
    {
      title: "Add GIS layers to the map",
      link: "https://nd-website-data.s3.ap-southeast-1.amazonaws.com/screen-recordings/Layers-on-top-of-search-results.mp4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing lorem ipsum dolor. Cum sociis natoque penatibus",
    },

    {
      title: "Autocomplete multiselect filters",
      link: "https://nd-website-data.s3.ap-southeast-1.amazonaws.com/screen-recordings/Autocomplete-Multiselect-filters.mp4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing lorem ipsum dolor. Cum sociis natoque penatibus",
    },
    {
      title: "Advanced search using operators",
      link: "https://nd-website-data.s3.ap-southeast-1.amazonaws.com/screen-recordings/Phrase-Search-Operator-Combination.mp4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing lorem ipsum dolor. Cum sociis natoque penatibus",
    },
  ],
  productFeatures: {
    heading:
      "Digitising mining information using the latest in Artificial Intelligence",
    items: [
      {
        heading: "Powered by latest AI models",
        description:
          "We utilize the latest OCR, data mining, and Natural Language Processing (NLP) techniques to extract, catalogue, and annotate information from scanned documents.",
        svgUrl: "AI.svg",
      },
      {
        heading: "Search everywhere",
        description:
          "Our search engine provides you the  ability to search through text, tables and images in your reports",
        svgUrl: "SearchEverywhere.svg",
      },
      {
        heading: "Advanced search capabilities",
        description:
          "We give you the power to narrow down your search to a spatial region or to search for an exact phrase in a specific metadata field.",
        svgUrl: "SearchGeolocation.svg",
      },
      {
        heading: "Visualize search results on map",
        description:
          "Our search engine not only returns the relevant reports, but also shows you the associated geolocation data side-by-side on a map.",
        svgUrl: "VisualizeMap.svg",
      },
      {
        heading: "Contextualize your search",
        description:
          "Our online platform provides you the ability to contextualise your search results with relevant GIS datasets. You can also export the search results and use them in your favorite applications.",
        svgUrl: "layer.svg",
      },
      {
        heading: "Hassle-free setup across platforms",
        description:
          "Sometimes, sharing the data with a 3rd party is not feasible. We can deploy our platform to your on-premise servers or your cloud infrastructure. We won't mind at all.",
        svgUrl: "deploy.svg",
      },
    ],
  },
  landing: {
    heading: {
      steps: ["Search", 1000, "Find", 1000, "Explore", 1000],
      loop: Infinity,
      constantText: "differently",
    },
    subheading: {
      text: "Data mining platform for open file mining and exploration reports",
    },
    contactUs: {
      text: "Schedule Free Demo",
    },
  },
};
