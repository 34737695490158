import { config } from "../../config";
import { useState } from "react";
export const ScreenCaptures = () => {
  const [currVideoIdx, setCurrVideoIdx] = useState(0);
  return (
    <>
      <div class="bg-gray-900 h-full">
        <div class="text-4xl font-semibold text-center py-10 text-yellow-500 ">
          See our Platform in Action
        </div>

        <div class="container mx-auto py-10 grid grid-cols-1 gap-4 h-full justify-items-start">
          <div class="bg-gray-900 text-white mt-5 col-span-1">
            <video
              class="border border-gray-200 rounded-2xl"
              preload="auto"
              controls
              loop
              key={currVideoIdx}
            >
              <source src={config.videos[currVideoIdx].link}></source>
            </video>
          </div>
          <div class="col-span-1">
            <div class=" bg-gray-900 text-yellow-500 overflow-y-scroll mt-5 grid grid-cols-4 gap-4">
              {config.videos.map((v, idx) => (
                <div
                  onClick={(e) => setCurrVideoIdx(idx)}
                  class={`p-2 border border-gray-500 rounded-2xl text-center ${
                    idx === currVideoIdx
                      ? "text-yellow-500 bg-gray-700"
                      : "text-yellow-200"
                  } `}
                >
                  <button class="font-bold text-xl text-center">
                    {v.title}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
