import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavBar } from "./components/Navbar";
import { MainPage } from "./Main";
import { AboutUs } from "./components/AboutUs";
import { Footer } from "./components/Footer";
import { ContactUs } from "./components/ContactUs";
import { Login } from "./components/Login";
function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/privacy" element={<h1>Privacy</h1>} />
          <Route path="/faq" element={<h1>FAQ</h1>} />
          <Route path="/toc" element={<h1>Terms of Service</h1>} />
        </Routes>
        <div class="w-full border-t border-secondary-500"></div>

        <Footer />
      </Router>
    </>
  );
}

export default App;
