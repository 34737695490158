import { Link } from "react-router-dom";

export const NavBar = () => (
  <>
    <nav class="z-10 flex sticky top-0 items-center justify-between flex-wrap bg-gray-900 w-full">
      <div class="flex items-center flex-shrink-0 text-black mr-6 ml-3">
        <a href="/">
          <img
            class=" w-24 h-24 -my-4"
            src={process.env.PUBLIC_URL + "/logo-white.png"}
            alt="logo"
          ></img>
        </a>
      </div>
      <div class="flex-grow flex items-center w-auto">
        <div class="flex-grow"></div>
        <div class="mr-3">
          {/* <Link
            to="https://wa.staging.needle-digital.com/"
            class="inline-block mt-0 text-yellow-500 hover:text-yellow-200 mr-12"
          >
            Your problem
          </Link>
          <Link
            to="#about"
            class="inline-block mt-0 text-yellow-500 hover:text-yellow-200 mr-12"
          >
            Our Platform
          </Link>
          <Link
            to="#product"
            class="inline-block mt-0 text-yellow-500 hover:text-yellow-200 mr-12"
          >
            Our Products
          </Link> */}
          <Link
            to="/login"
            class="inline-block text-sm px-4 py-2 leading-none rounded text-black bg-yellow-500 mt-0 mr-4"
          >
            Login
          </Link>
          <Link
            to="/contact"
            class="inline-block text-sm px-4 py-2 leading-none rounded text-black bg-yellow-500 mt-0"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </nav>
  </>
);
