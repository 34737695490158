export const AboutUs = () => (
  <>
    <div class="container mx-auto">
      <div class="text-center text-xl font-light text-gray-500 italic mt-6 mb-3">
        Mining is evolving, and so are we
      </div>
      <hr />
      <div class="text-2xl font-bold text-secondary-500 mt-6">Who we are</div>
      <div class="mt-6 mb-6 ">
        <p class="leading-loose">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
          bibendum arcu sit amet purus congue auctor. Donec eget nulla enim.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Proin sollicitudin porta ipsum, vitae
          interdum velit cursus vitae. In id pellentesque quam, sit amet
          consectetur est. Phasellus laoreet tristique est ut hendrerit.
          Curabitur massa mauris, porta in turpis ut, ornare rutrum mauris.
          Suspendisse potenti. Aliquam pulvinar sapien at tincidunt suscipit.
          Integer pulvinar non nibh non iaculis. Aenean odio lorem, venenatis
          nec erat vel, lobortis facilisis dui. Duis pulvinar sollicitudin odio.
        </p>
      </div>
      <div class="text-2xl font-bold text-secondary-500 mt-6">
        Our inspiration
      </div>
      <div class="mt-6 mb-6">
        <p class="leading-loose">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
          bibendum arcu sit amet purus congue auctor. Donec eget nulla enim.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Proin sollicitudin porta ipsum, vitae
          interdum velit cursus vitae. In id pellentesque quam, sit amet
          consectetur est. Phasellus laoreet tristique est ut hendrerit.
          Curabitur massa mauris, porta in turpis ut, ornare rutrum mauris.
          Suspendisse potenti. Aliquam pulvinar sapien at tincidunt suscipit.
          Integer pulvinar non nibh non iaculis. Aenean odio lorem, venenatis
          nec erat vel, lobortis facilisis dui. Duis pulvinar sollicitudin odio.
        </p>
      </div>
      <div class="text-2xl font-bold text-secondary-500 mt-6">
        What can we do for you
      </div>
      <div class="mt-6 mb-6">
        <p class="leading-loose">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
          bibendum arcu sit amet purus congue auctor. Donec eget nulla enim.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Proin sollicitudin porta ipsum, vitae
          interdum velit cursus vitae. In id pellentesque quam, sit amet
          consectetur est. Phasellus laoreet tristique est ut hendrerit.
          Curabitur massa mauris, porta in turpis ut, ornare rutrum mauris.
          Suspendisse potenti. Aliquam pulvinar sapien at tincidunt suscipit.
          Integer pulvinar non nibh non iaculis. Aenean odio lorem, venenatis
          nec erat vel, lobortis facilisis dui. Duis pulvinar sollicitudin odio.
        </p>
      </div>
      <div id="whyus" class="text-2xl font-bold text-secondary-500 mt-6">
        Why us?
      </div>
      <div class="mt-6 mb-6">
        <p class="leading-loose">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
          bibendum arcu sit amet purus congue auctor. Donec eget nulla enim.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Proin sollicitudin porta ipsum, vitae
          interdum velit cursus vitae. In id pellentesque quam, sit amet
          consectetur est. Phasellus laoreet tristique est ut hendrerit.
          Curabitur massa mauris, porta in turpis ut, ornare rutrum mauris.
          Suspendisse potenti. Aliquam pulvinar sapien at tincidunt suscipit.
          Integer pulvinar non nibh non iaculis. Aenean odio lorem, venenatis
          nec erat vel, lobortis facilisis dui. Duis pulvinar sollicitudin odio.
        </p>
      </div>
    </div>
  </>
);
