import { Link } from "react-router-dom";
import Typical from "react-typical";
import { config } from "../../config";

export const Landing = () => (
  <>
    <div
      class="grid h-screen bg-no-repeat bg-cover items-center"
      style={{
        "background-image":
          "linear-gradient(rgba(17, 24, 39, 0.85), rgba(17, 24, 39, 0.85)),url(/background.png)",
      }}
    >
      <div id="product_description" class="text-5xl m-12 text-yellow-500">
        <div class="font-serif flex">
          <Typical
            steps={config.landing.heading.steps}
            loop={config.landing.heading.loop}
            wrapper="p"
          />{" "}
          {config.landing.heading.constantText}
        </div>
        <div class="text-2xl text-yellow-200 mt-10">
          {config.landing.subheading.text}
        </div>
        <div class="mt-20">
          <button class="inline-bloc text-xl p-4 rounded-xl text-black bg-yellow-500 text-center transform-gpu ease-in-out hover:scale-110 duration-300 hover:bg-yellow-200">
            <Link to="/contact">{config.landing.contactUs.text}</Link>
          </button>
        </div>
        <div></div>
      </div>
    </div>
  </>
);
