import { Landing } from "./components/Landing";
import { ScreenCaptures } from "./components/ScreenCaptures";
import { ProductFeatures } from "./components/ProductFeatures";

export const MainPage = () => (
  <>
    <Landing />
    <ProductFeatures />
    <ScreenCaptures />
  </>
);
