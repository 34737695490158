import { config } from "../../config";

const Card = ({ heading, description, svgUrl }) => (
  <>
    <div class="transform-gpu ease-in-out hover:scale-110 duration-300 w-full">
      <div class="flex justify-center p-5">
        <img
          src={`${process.env.PUBLIC_URL}/svgs/${svgUrl}`}
          class="w-1/5 text-yellow-500 items-center justify-center align-middle text-center"
          alt="Feature preview"
        ></img>
      </div>

      <div class="text-yellow-500 font-bold text-2xl">{heading}</div>
      <div class="transition duration-150 ease-out leading-loose text-justify text-yellow-200 justify-items-start p-5">
        {description}
      </div>
    </div>
  </>
);

export const ProductFeatures = () => {
  return (
    <div class="bg-gray-900 text-white">
      <div class="text-4xl font-semibold text-center text-yellow-500 pt-20">
        {config.productFeatures.heading}
      </div>

      <div class="grid grid-cols-3 mt-20 justify-items-center h-full text-center gap-10 container mx-auto">
        {config.productFeatures.items.map((pf) => (
          <Card {...pf} />
        ))}
      </div>
    </div>
  );
};
