export const Login = () => {
  return (
    <div class="bg-gray-900">
      <div class="text-center font-bold text-4xl text-secondary-500">
        Data Mines
      </div>
      <div class="container mx-auto py-12 ">
        <div class="grid grid-cols-3 justify-items-center gap-12">
          <a
            href="https://wa.needle-digital.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/WA-map.png" alt="Western Australia Map" />
            <p class="text-secondary-500 mt-5 text-center text-xl font-bold">
              Western Australia Portal
            </p>
          </a>
          <a
            href="https://nt.staging.needle-digital.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/NT-map.png" alt="Northern Territory Map" />
            <p class="text-secondary-500 mt-5 text-center text-xl font-bold">
              Northern Territory Portal
            </p>
          </a>
          <div>
            <img src="/QLD-map.png" alt="Western Australia Map" />
            <p class="text-secondary-500 mt-5 text-center text-xl font-bold">
              Queensland Portal [Coming soon]
            </p>
          </div>

          <div>
            <img src="/SA-map.png" alt="Western Australia Map" />
            <p class="text-secondary-500 mt-5 text-center text-xl font-bold">
              Southern Australia Portal [Coming soon]
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
