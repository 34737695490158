import { Link } from "react-router-dom";

const CompanyDescription = () => (
  <>
    <div class="ml-6 mb-10 text-white">
      <div class="font-bold text-4xl mb-10 mt-10 text-secondary-500">
        Needle Digital
      </div>
      <div class=" font-extralight text-lg mb-10 text-secondary-200">
        <p class="leading-relaxed">
          Needle Digital Pty. Ltd. <br />
          Perth, WA, Australia.
        </p>

        <span class="font-semibold">ABN 27 655 695 373</span>
        <br />
      </div>
    </div>
  </>
);

const Products = () => (
  <div class="text-white mb-10 mt-10">
    <div class="font-bold text-secondary-500 text-2xl">Products</div>
    <div class="mt-10 text-secondary-200">
      <a
        href="https://wa.needle-digital.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        WA
      </a>
    </div>
    <div class="mt-4 text-secondary-200">
      <a
        href="https://nt.staging.needle-digital.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        NT
      </a>
    </div>
    <div class="mt-4 text-secondary-200">
      <p> QLD (Coming soon) </p>
    </div>
    <div class="mt-4 text-secondary-200">
      <p> SA (Coming soon) </p>
    </div>
  </div>
);

// const AboutUs = () => (
//   <div class="text-secondary-500 mb-10 mt-10">
//     <div class="font-bold text-2xl">About Us</div>
//     <div class="mt-10 text-secondary-200">
//       <Link to="/about"> Company </Link>
//     </div>
//     <div class="mt-4 text-secondary-200">
//       <Link to="/about#whyus"> Why Us </Link>
//     </div>
//     <div class="mt-4 text-secondary-200">
//       <Link to="/contact"> Contact Us </Link>
//     </div>
//   </div>
// );

const Resources = () => (
  <div class="text-white mb-10 mt-10">
    <div class="font-bold text-2xl text-secondary-500">Resources</div>
    <div class="mt-10 text-secondary-200">
      <Link to="/faq"> LinkedIn </Link>
    </div>
    <div class="mt-4 text-secondary-200">
      <Link to="/privacy"> Privacy Policy </Link>
    </div>
    <div class="mt-4 text-secondary-200">
      <Link to="/toc"> Terms of Service </Link>
    </div>
  </div>
);

export const Footer = () => (
  <>
    <div class="grid bg-gray-900">
      <div class="grid grid-cols-10">
        <div class="col-span-4">
          <CompanyDescription />
        </div>
        <div class="col-span-3">
          <Products />
        </div>
        {/* <div class="col-span-2">
          <AboutUs />
        </div> */}
        <div class="col-span-3">
          <Resources />
        </div>
      </div>
    </div>
  </>
);
