import { useState } from "react";
import axios from "axios";
import { config } from "../../config";

const handleSubmit = (senderName, senderEmail, message) => {
  axios
    .post(config.contactUsURL, {
      senderName,
      senderEmail,
      message,
    })
    .then(() => {
      alert("We've received your request");
    })
    .catch(() => {
      alert("Some error occurred. Please try again later");
    });
};
export const ContactUs = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [initSendRequest, setInitSendRequest] = useState(false);

  const handleReset = () => {
    setName("");
    setEmail("");
    setMessage("");
    setInitSendRequest(false);
  };
  return (
    <div class="bg-gray-900">
      <div class="container mx-auto">
        <p class="text-4xl text-center pt-10 pb-20 font-bold text-secondary-500">
          Get in touch with us!
        </p>

        <div class="grid pb-10 ">
          <div class="grid grid-cols-10 gap-4">
            <div class="col-span-4 rounded-3xl p-10 text-secondary-500 border border-secondary-500">
              <div class="text-3xl font-semibold ">Contact Information</div>
              <div class="mt-10 text-xl font-light text-secondary-200">
                Fill up the form and our team will reach out to you shortly.
              </div>
              <div class="mt-10 text-xl font-light text-secondary-200">
                Or you can contact us directly at{" "}
                <a
                  class="inline-block text-secondary-500"
                  href="mailto:info@needle-digital.com"
                >
                  info@needle-digital.com
                </a>
              </div>
            </div>
            <div class="col-span-6 border border-secondary-500 rounded-3xl">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (initSendRequest) {
                    alert(
                      "You've already submitted the request once. Please refresh your browser or click on reset to send another request."
                    );
                  } else {
                    handleSubmit(name, email, message);
                    setInitSendRequest(true);
                  }
                }}
              >
                <div class="p-12">
                  <div class="max-w-md">
                    <div class="grid grid-cols-1 gap-6">
                      <label class="block">
                        <span class="text-secondary-200">Full name</span>
                        <input
                          required
                          onInput={(event) => setName(event.target.value)}
                          type="text"
                          class="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200
                    focus:ring-0 focus:border-black
                  "
                          placeholder=""
                        />
                      </label>
                      <label class="block">
                        <span class="text-secondary-200">Email address</span>
                        <input
                          required
                          onInput={(event) => setEmail(event.target.value)}
                          type="email"
                          class="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200
                    focus:ring-0 focus:border-black
                  "
                          placeholder=" john@example.com"
                        />
                      </label>
                      <label class="block">
                        <span class="text-secondary-200">Message</span>
                        <textarea
                          required
                          onInput={(event) => setMessage(event.target.value)}
                          class="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200
                    focus:ring-0 focus:border-black
                  "
                          rows="2"
                        ></textarea>
                      </label>
                      <div class="grid grid-cols-1 justify-items-center">
                        <div>
                          <div class="inline-block items-center justify-center">
                            <button
                              type="submit"
                              class="rounded-md bg-secondary-500 mx-auto p-2 pl-4 pr-4 mt-10"
                            >
                              Submit
                            </button>
                          </div>
                          <div class="inline-block ml-5">
                            <button
                              type="reset"
                              onClick={handleReset}
                              class="rounded-md bg-red-400 mx-auto p-2 pl-4 pr-4 mt-10"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
